// import { genesisIcons } from "@formkit/icons";
import { en, ja } from "@formkit/i18n";
import { createAutoAnimatePlugin } from "@formkit/addons";
import { generateClasses } from "@formkit/themes";

import phone from "@/formkit/custom-rules/phone";
import addAsteriskPlugin from "@/formkit/plugins/add-asterisk-plugin";

// import "@formkit/themes/genesis" // @formkit/themes/dist/genesis/theme.css
// import genesis from '@formkit/themes/tailwindcss/genesis'
// import theme from './theme-default' // genesis theme
import theme from "./theme";

export default {
  config: {
    // classes: generateClasses(genesis)
    classes: generateClasses(theme)
  },
  icons: {
    // ...genesisIcons
    checkboxDecorator: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <circle fill="currentColor" cx="16" cy="16" r="8"/></svg>`,
    radioDecorator: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <circle fill="currentColor" cx="16" cy="16" r="8"/></svg>`
  },
  locales: { en, ja }, // supported locales
  locale: "ja",
  messages: {
    en: {
      validation: {
        required() {
          return "*Required";
        },
        accepted() {
          return "";
        }
      }
    },
    ja: {
      validation: {
        required() {
          return "*必須です";
        },
        accepted() {
          return "";
        }
      }
    }
  },
  plugins: [
    addAsteriskPlugin,
    createAutoAnimatePlugin(
      {
        /* optional AutoAnimate config */
        // default:
        duration: 250,
        easing: "ease-in-out",
        delay: 0
      },
      {
        /* optional animation targets object */
        // default:
        global: ["outer", "inner"],
        form: ["form"],
        repeater: ["items"]
      }
    )
  ],
  rules: {
    phone
  }
};
