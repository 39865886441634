import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { getAuth } from "firebase/auth";
import { createHead } from "unhead";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";

import VCalendar from "v-calendar";
import "v-calendar/style.css";

import { plugin, defaultConfig } from "@formkit/vue";
import formkitConfig from "./formkit/config";

// import { handleError } from "./composables/utils";

import "./index.css";

import en from "./locale/en";
import ja from "./locale/ja";

import datetimeFormats from "./common/locale/date-time-format";
// eslint-disable-next-line no-unused-vars
// import user from "@/composables/user"; // @TODO If this isn't added here, `user` will never be initialized

const i18n = createI18n({
  legacy: false, // to use Composition API, set to `false`
  messages: { en, ja }, // set locale messages
  locale: "ja", // set active locale
  fallbackLocale: "ja", // set fallback locale
  datetimeFormats
});

// Create a global head instance
// https://unhead.harlanzw.com/guide/getting-started/setup
createHead();

// Automatically refresh app when authentication state changes
let app; // Note: this needs to be a global variable
getAuth().onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    // app.config.errorHandler = async (err, instance, info) => {
    //   // https://vuejs.org/api/application.html#app-config-errorhandler
    //   handleError({err, instance, info});
    // }

    Sentry.init({
      app,
      dsn: "https://f77a6a9ee0ab1dd656d235bb99201dce@o4507216097640448.ingest.us.sentry.io/4507216099278848",
      // https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskFn: (s) => s[0] + s.slice(1).replace(/./g, "*")
        })
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/booking\.pd-navi\.co\.jp\.io/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.use(router);
    app.use(i18n);
    app.use(VCalendar, {});
    app.use(plugin, defaultConfig(formkitConfig));
    app.mount("#app");
  }
});

// Handle load dynamic import  errors
// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault(); // prevent default handler
  console.warn("Failed to fetch dynamically imported module", event.payload);
  console.log("Reloading the page...");
  window.location.reload(); // for example, refresh the page
});
