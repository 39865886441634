import { ref } from "vue";
import { doc, onSnapshot, updateDoc, serverTimestamp } from "firebase/firestore";

import { firestore } from "@/firebase/config";
import { isDate } from "@/composables/utils";
import clinic from "@/composables/clinic";
import service from "@/composables/service";
import datetimeFormats from "@/common/locale/date-time-format";

/**
 * Add minutes to current datetime object
 * @param {number} minutes Minutes
 * @returns {number} Datetime object
 */
// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (minutes) {
  // eslint-disable-next-line no-invalid-this
  const date = new Date(this.valueOf());
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

const appointment = (() => {
  const DEFAULT_DATE = "2001-12-31";

  let _unsubscribe;

  const id = ref(""); // appointmentId

  const patientId = ref("");
  const contact = ref({});

  const status = ref("");

  const event = ref({
    datetime: "",
    clientCalendarId: "",
    timeZone: ""
  });
  const session = ref();
  const registrant = ref({
    email: "",
    relationship: {}
  });

  const parents = ref({});
  const family = ref([]);

  const ovus = ref();
  const fertilityTreatmentHistory = ref({});

  const pregnancyCurrent = ref(); // undefined means that patient is not currently pregnant
  const pregnancyHistory = ref();
  const prenatalTesting = ref();

  const formContactInfo = ref({});
  const formExpectations = ref({});
  const formFertilityTreatmentHistory = ref({});
  const formPregnancyCurrent = ref({});
  const formPregnancyHistory = ref({});
  const formPrenatalTesting = ref({});
  const formProfile = ref({});

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const init = async (docId) => {
    deinit();

    console.debug("Initializing appointment data...", docId);
    _unsubscribe = onSnapshot(doc(firestore, "appointments", docId), async (doc) => {
      if (doc.exists) {
        id.value = doc.id;

        const _data = doc.data();
        if (!_data) {
          return;
        }

        // Fill appointment data attributes
        if (Object.prototype.hasOwnProperty.call(_data, "patientId")) {
          patientId.value = _data.patientId;
        } else {
          patientId.value = "";
        }

        if (Object.prototype.hasOwnProperty.call(_data, "contact")) {
          contact.value = _data.contact;
        } else {
          contact.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "status")) {
          status.value = _data.status;
        } else {
          status.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "event")) {
          event.value = _data.event;
          event.value.datetime = event.value.datetime.toDate();
        } else {
          event.value = {
            datetime: "",
            clientCalendarId: "",
            timeZone: ""
          };
        }

        if (Object.prototype.hasOwnProperty.call(_data, "session")) {
          session.value = _data.session;
        } else {
          session.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "registrant")) {
          registrant.value = _data.registrant;
        } else {
          registrant.value = {
            email: "",
            relationship: {}
          };
        }

        if (Object.prototype.hasOwnProperty.call(_data, "parents")) {
          parents.value = _data.parents;
        } else {
          parents.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "family")) {
          family.value = _data.family;
        } else {
          family.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "ovus")) {
          ovus.value = _data.ovus;
        } else {
          ovus.value = {
            cases: [
              {
                date: `${new Date().getFullYear() - 1}-12-31`
              }
            ]
          };
        }

        if (Object.prototype.hasOwnProperty.call(_data, "fertilityTreatmentHistory")) {
          fertilityTreatmentHistory.value = _data.fertilityTreatmentHistory;
        } else {
          fertilityTreatmentHistory.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "pregnancyCurrent")) {
          pregnancyCurrent.value = _data.pregnancyCurrent;
        } else {
          pregnancyCurrent.value = undefined;
        }

        if (Object.prototype.hasOwnProperty.call(_data, "pregnancyHistory")) {
          pregnancyHistory.value = _data.pregnancyHistory;
        } else {
          pregnancyHistory.value = undefined;
        }

        if (Object.prototype.hasOwnProperty.call(_data, "prenatalTesting")) {
          prenatalTesting.value = _data.prenatalTesting;
        } else {
          prenatalTesting.value = undefined;
        }

        // Form data
        if (Object.prototype.hasOwnProperty.call(_data, "_formContactInfo")) {
          formContactInfo.value = _data._formContactInfo;
        } else {
          formContactInfo.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "_formExpectations")) {
          formExpectations.value = _data._formExpectations;
        } else {
          formExpectations.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "_formFertilityTreatmentHistory")) {
          formFertilityTreatmentHistory.value = _data._formFertilityTreatmentHistory;
        } else {
          formFertilityTreatmentHistory.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "_formPregnancyHistory")) {
          formPregnancyHistory.value = _data._formPregnancyHistory;
        } else {
          formPregnancyHistory.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "_formPregnancyCurrent")) {
          formPregnancyCurrent.value = _data._formPregnancyCurrent;
        } else {
          formPregnancyCurrent.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "_formPrenatalTesting")) {
          formPrenatalTesting.value = _data._formPrenatalTesting;
        } else {
          formPrenatalTesting.value = {};
        }

        if (Object.prototype.hasOwnProperty.call(_data, "_formProfile")) {
          formProfile.value = _data._formProfile;
        } else {
          formProfile.value = {
            mother: {
              dob: DEFAULT_DATE
            },
            partner: {
              dob: DEFAULT_DATE
            }
          };
        }

        // Get service and clinic _data
        if (_data.clinicId && _data.serviceId) {
          await clinic.init(_data.clinicId);
          await service.init(_data.serviceId);
        }
      }
    });
    return;
  };

  const deinit = () => {
    if (_unsubscribe) {
      _unsubscribe();
    }
  };

  const update = async (data) => {
    console.debug("Updating appointment data...", id.value);
    if (id.value) {
      await updateDoc(doc(firestore, "appointments", id.value), {
        updated: serverTimestamp(),
        ...data
      });
    } else {
      console.error("Undefined appointment ID");
    }
  };

  const getDateString = (locale) => {
    if (event.value.datetime && locale) {
      const startDate = event.value.datetime;
      if (isDate(startDate)) {
        return new Intl.DateTimeFormat(locale, {
          ...datetimeFormats[locale].dateOnly,
          timeZone
        }).format(startDate);
      }
    }
  };

  const getTimeString = (locale) => {
    if (
      event.value.datetime &&
      service.data.value.appointmentDuration &&
      service.data.value.appointmentDuration.patient
    ) {
      const startDate = event.value.datetime;
      const endDate = startDate.addMinutes(service.data.value.appointmentDuration.patient);
      if (isDate(startDate)) {
        return `
        ${new Intl.DateTimeFormat(locale, {
          ...datetimeFormats[locale].timeOnly,
          timeZone
        })
          .format(startDate)
          .replace(/AM|PM/, "")
          .trim()}-${new Intl.DateTimeFormat(locale, {
          ...datetimeFormats[locale].timeOnly,
          timeZone
        }).format(endDate)}
        `;
      }
    }
  };

  const getTimeZone = (locale) => {
    return new Intl.DateTimeFormat(locale, {
      timeZoneName: "long",
      timeZone
    })
      .format(new Date())
      .split(" ")
      .slice(1)
      .join(" ");
  };

  const refs = () => {
    return {
      id,
      patientId,
      contact,
      status,
      event,
      session,
      registrant,
      parents,
      family,
      ovus,
      fertilityTreatmentHistory,
      pregnancyCurrent,
      pregnancyHistory,
      prenatalTesting,
      formContactInfo,
      formExpectations,
      formFertilityTreatmentHistory,
      formPregnancyCurrent,
      formPregnancyHistory,
      formPrenatalTesting,
      formProfile
    };
  };

  return {
    init,
    deinit,
    refs,
    update,
    getDateString,
    getTimeString,
    getTimeZone
  };
})();

export default appointment;
