import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";

// import appointment from "@/composables/appointment";

// Navigation guard
// https://router.vuejs.org/guide/advanced/navigation-guards.html
const requireAuth = async (to, from, next) => {
  if (getAuth().currentUser) {
    // console.debug("nav-guard");
    // const userData = await user.read(thisUser.uid);
    // await user.fill(userData);
    // if (appointment.isConfirmed()) {
    //   if (to.name !== "booking-review") {
    //     // Redirect to the review page if appointment is already confirmed
    //     next({ name: "booking-review" });
    //   } else {
    //     next();
    //   }
    // } else {
    //   next();
    // }
    next();
  } else {
    next({ name: "service-all" });
  }
};

let routes = [
  {
    path: "/",
    name: "service-all",
    component: () => import("../views/services/all.vue")
  },
  {
    path: "/prenatal",
    alias: ["/prenatal-b2b"],
    name: "service-prenatal",
    component: () => import("../views/services/prenatal.vue")
  },
  {
    path: "/pgt",
    alias: ["/pgt-post"],
    name: "service-pgt",
    component: () => import("../views/services/pgt.vue")
  },
  {
    path: "/cancer",
    name: "service-cancer",
    component: () => import("../views/services/cancer.vue")
  },
  {
    path: "/auth/login",
    name: "login",
    component: () => import("../views/login.vue"),
    props: (route) => ({
      serviceType: route.query.serviceType
    })
  },
  {
    path: "/auth/confirmation",
    name: "auth-confirmation",
    component: () => import("../views/confirmation/auth-link.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
    props: (route) => ({
      id: route.query.id,
      name: route.query.name,
      email: route.query.email
    })
  },
  {
    path: "/contact/confirmation",
    name: "contact-confirmation",
    component: () => import("../views/confirmation/contact.vue")
  },
  {
    path: "/booking/clinic-code",
    alias: ["/booking"],
    name: "booking-clinic-code",
    component: () => import("../views/booking/clinic-code.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/clinic-id",
    name: "booking-clinic-id",
    component: () => import("../views/booking/clinic-id.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/contact-info",
    name: "booking-contact-info",
    component: () => import("../views/booking/contact-info.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/profile",
    name: "booking-profile",
    component: () => import("../views/booking/profile.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/calendar",
    name: "booking-calendar",
    component: () => import("../views/booking/calendar.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/family",
    name: "booking-family",
    component: () => import("../views/booking/family.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/fertility-treatment",
    name: "booking-fertility-treatment",
    component: () => import("../views/booking/fertility-treatment.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/pregnancy",
    name: "booking-pregnancy-current",
    component: () => import("../views/booking/pregnancy-current.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/pregnancy-history",
    name: "booking-pregnancy-history",
    component: () => import("../views/booking/pregnancy-history.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/prenatal-testing",
    name: "booking-prenatal-testing",
    component: () => import("../views/booking/prenatal-testing.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/expectations",
    name: "booking-expectations",
    component: () => import("../views/booking/expectations.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/review",
    name: "booking-review",
    component: () => import("../views/booking/review.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/confirmed",
    name: "booking-confirmed",
    component: () => import("../views/booking/confirmed.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/booking/pgt-reports",
    name: "booking-ovus",
    component: () => import("../views/booking/ovus.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error.vue")
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404.vue")
  }
];

const devRoutes = [
  // TESTING
  {
    path: "/_/_/dev/calendar",
    name: "dev-calendar",
    component: () => import("../views/dev/calendar.vue")
  },
  {
    path: "/_/_/dev/code",
    name: "dev-code",
    component: () => import("../views/dev/code.vue")
  },
  {
    path: "/_/_/dev/css",
    name: "dev-css",
    component: () => import("../views/dev/css.vue")
  },
  {
    path: "/_/_/dev/date",
    name: "dev-date",
    component: () => import("../views/dev/date.vue")
  },
  {
    path: "/_/_/dev/formkit-schema",
    name: "dev-formkit-schema",
    component: () => import("../views/dev/formkit-schema.vue")
  },
  {
    path: "/_/_/dev/layout",
    name: "dev-layout",
    component: () => import("../views/dev/layout.vue")
  },
  {
    path: "/_/_/dev/modal",
    name: "dev-modal",
    component: () => import("../views/dev/modal.vue")
  },
  {
    path: "/_/_/dev/timezone",
    name: "dev-timezone",
    component: () => import("../views/dev/timezone.vue")
  }
];

if (import.meta.env.DEV) {
  routes = routes.concat(devRoutes);
}

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    // always scroll to top
    return { top: 0 };
  }
});

export default router;
