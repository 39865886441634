const pregnancyCurrent = {
  en: {
    isPregnant: "Are you currently pregnant?",
    edd: "What is your expected delivery date?",
    fetusType: "How many fetuses are present in this pregnancy?",
    isIvf:
      "Was this pregnancy achieved through assisted reproductive technology (ART), such as in vitro fertilization (IVF) or microinsemination?",
    eggCollectionDate: "Please provide the date of egg collection.",
    fetusDisorder: {
      bool: "Have any potential fetal abnormalities been identified at this time?",
      select: "Please identify the abnormality.",
      others:
        "ⓘ Please write the names of other abnormalities here. Separate each abnormality with a comma (,).",
    },
    pgt: {
      bool: "Was preimplantation genetic testing (PGT) performed to evaluate embryo health?",
      select: "What were the results?",
      details: "Please share details of the results.",
    },
    help: {
      eggCollectionDate:
        "ⓘ If you do not know the exact date of egg collection, please enter '1', e.g. December 1, 2023",
    },
  },
  ja: {
    isPregnant: "現在、妊娠中ですか。",
    edd: "分娩予定日をお聞かせください。",
    fetusType: "胎児数をお聞かせください。",
    isIvf: "体外受精または顕微授精による妊娠ですか。",
    eggCollectionDate: "採卵日をお聞かせください。",
    fetusDisorder: {
      bool: "今回の妊娠について、現時点で赤ちゃんの病気の可能性は指摘されていますか。",
      select: "指摘されている内容をお聞かせください。",
      others:
        "ⓘ 具体的にお聞かせください。複数ある場合は、指摘されている内容（病気の名称）をカンマで区切って下さい。",
    },
    pgt: {
      bool: "体外受精または顕微授精による妊娠の方にお尋ねします。着床前検査（PGT)※を受けましたか。（※受精卵の染色体異常の有無などを調べる検査です。）",
      select: "PGTの結果をお聞かせください。",
      details: "結果を具体的にお聞かせください。",
    },
    help: {
      eggCollectionDate:
        "ⓘ 採卵日の日付が分からない方は、「1日」とご入力ください。例）2023年12月１日",
    },
  },
};

export default pregnancyCurrent;
