import expectations from "@/common/locale/forms-questions/expectations";
import family from "@/common/locale/forms-questions/family";
import fertilityTreatment from "@/common/locale/forms-questions/fertility-treatment";
import pregnancyCurrent from "@/common/locale/forms-questions/pregnancy-current";
import pregnancyHistory from "@/common/locale/forms-questions/pregnancy-history";
import prenatal from "@/common/locale/forms-questions/prenatal";
import ovus from "@/common/locale/forms-questions/ovus";

const en = {
  expectations: expectations["en"],
  family: family["en"],
  fertilityTreatment: fertilityTreatment["en"],
  ovus: ovus["en"],
  pregnancyCurrent: pregnancyCurrent["en"],
  pregnancyHistory: pregnancyHistory["en"],
  prenatal: prenatal["en"],
  answers: {
    yes: "Yes",
    no: "No",
    none: "None"
  },
  banners: {
    autoSaveExpiry:
      "Your information will be saved for this session. However, to guarantee it's retained, please submit your reservation before the end of the day."
  },
  buttons: {
    addFamilyMember: "Add family member",
    addAnalysisReport: "Add report",
    cancel: "Cancel",
    cancelAppointment: "Cancel appointment",
    contactUs: "Contact us",
    continue: "Continue",
    deleteAccount: "Delete account",
    display: "Display",
    goBack: "Go back",
    goBackHome: "Go back home",
    login: "Login",
    logout: "Sign out",
    quickFilter: "Filter",
    reserve: "Reserve",
    resetFilter: "Reset filter",
    resetSearch: "Clear search",
    save: "Save",
    saveAndContinue: "Save and continue",
    search: "Search",
    showColumns: "Display",
    showMore: "Show more",
    submit: "Submit"
  },
  cards: {
    calendar: {
      title: "Calendar",
      description: "Upcoming events"
    },
    counselingInfo: {
      title: "Counseling",
      description: "Counseling session information",
      links: {
        clinicHandoverList: "Clinic handover list",
        consentProcedures: "Consent procedures",
        counselingSlides: "Counseling slides",
        counselingReportTemplate: "Clinic report template",
        googleCalendarEvent: "Google calendar event",
        googleMeet: "Google meet",
        recordsIndex: "Records index",
        reportGenerator: "Report generator",
        pgtReport: "PGT report",
        patientReportPdf: "Patient report PDF"
      }
    },
    clinicNotes: {
      title: "Clinic notes",
      empty: "None",
      saving: "Saving..."
    },
    counselingNotes: {
      title: "Counseling notes",
      placeholder: "Add counseling notes here."
    },
    patientInfo: {
      title: "Patient",
      description: "Patient contact information",
      country: "Country",
      prefecture: "Prefecture"
    },
    search: {
      title: "Search",
      description: "Search using the following criteria. All fields are optional.",
      appointmentId: "Appointment ID",
      patientId: "Patient ID",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      date: "Counseling date"
    },
    serviceInfo: {
      title: "Service"
    },
    sessionFlow: {
      title: "Session flow"
    }
  },
  dropdowns: {
    quickFilter: {
      today: "Today",
      tomorrow: "Tomorrow",
      yesterday: "Yesterday"
    }
  },
  error: {
    acceptTos:
      "To proceed with booking your appointment, please review and agree to the Terms of Service.",
    incomplete: "Please fill in the missing field(s).",
    invalidCode: "Invalid code",
    invalidEmail: "Invalid email address",
    invalidPatientId: "Invalid ID",
    invalidPhone: "Use only numbers (0-9) and dashes (-)"
  },
  function: {
    fullName: "{firstName} {lastName}"
  },
  help: {
    appointmentId:
      "ⓘ Including your appointment ID allows us to direct your inquiry to the appropriate counseling staff, facilitating a swifter response.",
    date: {
      mobile: "",
      desktop: ""
    },
    halfWidthCharacters: "",
    pleaseSpecify: "ⓘ Please specify"
  },
  images: {
    logo: "PDnavi Logo"
  },
  preamble: {
    clinicCode: "Please enter the medical institution code.",
    contactInfo:
      "This questionnaire is intended for the person receiving counseling. Please answer honestly - your answers will help us prepare for your session.",
    contactInfo2: "",
    expectations:
      "What are you hoping to get out of this counseling session? Feel free to share any concerns or questions you have.",
    family:
      "Has anyone in your family had a birth defect (congenital condition) or inherited disease? If so, please provide us with their relationship to you and the name of the condition.",
    pregnancyCurrent: "",
    pregnancyHistory: "Please share information about your past pregnancies and births.",
    prenatalTesting: "",
    profile: "Please provide the date of births for both partners.",
    review: {
      important: "Your reservation is not yet confirmed.",
      lead: "Please review the information below to ensure it is correct.",
      main: "Review your answers carefully. You can go back and make changes at any time. Once you are satisfied and agree to the terms of service below, click 'Submit' to confirm your reservation.",
      note: "Please be advised that your reservation information, including your appointment slot, may be cancelled unless you confirm your reservation before the end of the day.",
      notificationSent:
        "A confirmation email containing your appointment details has been sent to your registered email address for your reference. You may also log in at any time to review this information."
    },
    selectDateTime: "Please select the desired date and time for your appointment.",
    selectService: "Please select the service that you would like to use."
  },
  instruction: {
    changeAppointment: "To cancel or make changes to your appointment, please {contactUs}.",
    contactUs:
      "For inquiries regarding reservations, including pre-booking questions, changes, or cancellations, kindly utilize this form.",
    noClinic: "If you are not part of a clinic and/or do not have a clinic code, {skip}."
  },
  label: {
    appointment: "Appointment",
    appointmentDate: "Date",
    appointmentDateTime: "Date and time",
    appointmentId: "Appointment ID",
    clinic: "Medical institution",
    country: "Country",
    dob: "Date of birth",
    dobFemale: "Expectant mother's date of birth",
    dobPartner: "Partner's date of birth",
    email: "Email",
    language: "Language",
    location: "Location",
    message: "Message",
    name: {
      first: "First name",
      last: "Last name",
      full: "Full name",
      alias: "Alias"
    },
    noRecords: "No records",
    patientId: "Patient ID",
    phone: "Phone",
    registrant: "Who is registering this counseling session?",
    showing: "Showing",
    signedInAs: "Signed in as",
    subdivision: "Prefecture",
    time: "Time",
    timezone: "Timezone",
    tos: "Terms of service"
  },
  link: {
    contact: "Contact",
    contactUs: "contact us",
    ovus: "OVUS",
    privacyPolicy: "Privacy Policy",
    skip: "skip this step",
    tos: "Terms of Service"
  },
  modal: {
    cancelAppointment: {
      title: "Cancel appointment",
      description: "Are you sure you want to cancel this appointment?",
      details: {},
      input: {
        label: "Confirm",
        description: "Please type {code} to confirm."
      },
      button: "I understand the consequences, cancel the appointment"
    },
    clinicMessage: {
      title: "Send a message to PDnavi",
      placeholder: "Add a message about this patient for the counselor."
    },
    contactUs: {
      title: "Contact us"
    },
    selectGeneticTests: {
      title: "Select genetic tests",
      description: "Select the genetic tests that were taken."
    }
  },
  notification: {
    checkEmail: "Please check your email to proceed with your reservation.",
    confirmationAuthLink: "A verification email was sent to",
    confirmationAppointment: "A confirmation email was sent to {email}",
    confirmationContact: "Your message has been received. We will be in touch shortly.",
    expiredAuthLink: "The authentication link is no longer valid. Please {startNewSession}.",
    loadingCalendar: {
      first: "Checking appointment availability.",
      second: "Please wait..."
    },
    noAvailability: "No available times",
    noHistory: "No history",
    noInfo: "No information provided",
    noItems: "No items",
    notPregnant: "Not pregnant",
    reEnterEmail: "Please re-enter your email address.",
    returnHome: "Automatically returning to the home screen in {sec} seconds.",
    somethingWentWrong:
      "We apologize for the inconvenience. An unexpected error has occurred. Our team is investigating the issue.",
    startNewSession: "start a new session",
    unableToFindPage:
      "We apologize for the inconvenience, but the page you requested could not be located."
  },
  page: {
    title: "PDnavi Reservation System"
  },
  placeholder: {
    country: "Country of residence",
    familyRelationship: "Family member",
    findByAppointmentId: "Find by appointment ID",
    findByPatientId: "Find by patient ID",
    language: "Preferred language",
    message: "Type your message here",
    select: "Make a selection",
    subdivision: "Prefecture of residence"
  },
  question: {
    attendancePregnantWoman: "Pregnant client attendance"
  },
  sidebar: {
    appointmentList: "Appointments",
    clinicList: "Clinics",
    help: "Help",
    home: "Home"
  },
  table: {
    appointmentId: "Appointment ID",
    clinic: "Clinic",
    clinicId: "Code",
    confirmedDateTime: "Confirmed date and time",
    counselorName: "Counselor",
    counselingDateTime: "Counseling date and time",
    counselingNipt: "NIPT information provided",
    dob: "Date of birth",
    edd: "Expected date of birth",
    empty: "No items",
    loading: "Loading...",
    isPrinted: "Report printed",
    patientReport: "Counseling report",
    privateMessage: "Private message",
    name: "Name",
    selectedTests: "Selected tests",
    service: "Service",
    status: "Status",
    weeksPregnant: "Weeks pregnant"
  },
  tabs: {
    appointmentPresent: "Current appointment",
    appointmentPast: "Past appointments"
  },
  title: {
    appointment: "Appointment",
    clinicCode: "Clinic code",
    clinicMessage: "Clinic message",
    confirmationAppointment: "Appointment confirmed",
    confirmationAuthLink: "A verification email has been sent",
    confirmationContact: "Message delivered",
    contact: "Contact us",
    contactInfo: "Contact information",
    error: "System error",
    expectations: "Requests and expectations",
    family: "Family",
    fertilityTreatment: "Fertility treatment history",
    fourZeroFour: "Page not found",
    pregnancyCurrent: "Current pregnancy",
    pregnancyHistory: "Pregnancy history",
    prenatalTesting: "Prenatal testing",
    profile: "Profile",
    registrant: "Registrant",
    reservation: "Counseling reservation",
    review: "Review and submit",
    service: "Service"
  },
  service: {
    prenatal: "Prenatal testing",
    pgt: "Preimplantation genetic testing",
    cancer: "Oncology testing"
  },
  status: {
    unconfirmed: "Unconfirmed",
    confirmed: "Confirmed",
    complete: "Complete",
    canceled: "Canceled",
    noShow: "No-show"
  },
  tos: {
    iAgree: "I agree",
    iFullyAgree: "I have read and agree to the {tos}.",
    label: "Terms of Service",
    ovus: "In order to provide counseling services to our customers, we will receive disclosure of the results of the pre-implantation test from the medical institution and {ovus}, which has been commissioned by the medical institution. We will comply with laws and regulations, guidelines and our {privacyPolicy}, and handle pre-implantation test results appropriately.",
    pdnavi:
      "This service can only be used by customers who comply with our {tos}. Any personal information that is entered will be handled in accordance with our {privacyPolicy}."
  },
  validationMessage: {
    halfWidthAlphaNumericOnly: "Half-width alphanumeric characters only",
    invalidFormat: "Please enter in the specified format"
  }
};

export default en;
