const LANGUAGES = [
  { id: "ja", value: "日本語" },
  // { id: "en", value: "English" },
];

function isLocaleSupported(locale) {
  return LANGUAGES.map((x) => x.id).includes(locale);
}

export { LANGUAGES, isLocaleSupported };
