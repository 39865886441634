const pregnancyHistory = {
  en: {
    wasPregnant: "Have you previously been pregnant? ",
    hadMiscarriage:
      "Prior to this pregnancy, have you experienced pregnancy loss, including miscarriage or termination of pregnancy?",
    numPregnancies: "Please enter the number of previous pregnancies",
    numLiveBirths:
      "Please enter the number of live births that have resulted from your previous pregnancies.",
    multipleBirths:
      "Have you ever carried more than one fetus in a single pregnancy (e.g. twins, triplets, etc.)",
  },
  ja: {
    wasPregnant: "過去に妊娠のご経験はありますか。",
    hadMiscarriage:
      "これまでに自然流産や死産、人工流産（中絶）のご経験はありますか。「はい」を選択された場合、カウンセリング時に詳細をお伺いします。",
    numPregnancies: "過去の妊娠回数の合計について、数字をご入力ください。",
    numLiveBirths: "これまでの出産回数について、数字をご入力ください。",
    multipleBirths: "多胎妊娠の経験はありますか。",
  },
};

export default pregnancyHistory;
