import { sendError } from "@/firebase/functions";

/**
 * Send error message to web developer
 * @param {*} error Error object
 */
export async function handleError(error) {
  console.error(error);
  await sendError({
    timestamp: new Date(),
    userAgent: window.navigator.userAgent,
    locale: navigator.language,
    ...error
  });
}

/**
 * Async sleep
 * @param {Number} ms Milliseconds
 * @returns
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Checks if this is a valid date
 * @param {String} date Date string
 * @return {Boolean} true if valid date; false otherwise
 */
export function isDate(date) {
  const d = new Date(date);
  return d instanceof Date && !isNaN(d.valueOf());
}
