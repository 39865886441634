import expectations from "@/common/locale/forms-questions/expectations";
import family from "@/common/locale/forms-questions/family";
import fertilityTreatment from "@/common/locale/forms-questions/fertility-treatment";
import pregnancyCurrent from "@/common/locale/forms-questions/pregnancy-current";
import pregnancyHistory from "@/common/locale/forms-questions/pregnancy-history";
import prenatal from "@/common/locale/forms-questions/prenatal";
import ovus from "@/common/locale/forms-questions/ovus";

const ja = {
  expectations: expectations["ja"],
  family: family["ja"],
  fertilityTreatment: fertilityTreatment["ja"],
  ovus: ovus["ja"],
  pregnancyCurrent: pregnancyCurrent["ja"],
  pregnancyHistory: pregnancyHistory["ja"],
  prenatal: prenatal["ja"],
  answers: {
    yes: "はい",
    no: "いいえ",
    none: "なし"
  },
  banners: {
    autoSaveExpiry:
      "ご入力いただいた情報は自動的に保存されますが、当日中に予約を取得しなかった場合、予約枠を含む一部の情報が失われる可能性がありますのでご注意ください。"
  },
  buttons: {
    addFamilyMember: "家族の追加",
    addAnalysisReport: "報告書を追加",
    cancel: "キャンセル",
    cancelAppointment: "予約をキャンセルする",
    contactUs: "お問い合わせ",
    continue: "続ける",
    deleteAccount: "アカウントの削除",
    display: "表示",
    goBack: "戻る",
    goBackHome: "ホームに戻る",
    login: "ログイン",
    logout: "サインアウト",
    quickFilter: "フィルター",
    reserve: "予約はこちら",
    resetFilter: "フィルターをリセット",
    resetSearch: "検索をクリア",
    save: "保存",
    saveAndContinue: "保存して続行",
    search: "検索",
    showColumns: "表示",
    showMore: "もっと見る",
    submit: "送信"
  },
  cards: {
    calendar: {
      title: "カレンダー",
      description: "今後の予約・イベント"
    },
    counselingInfo: {
      title: "予約情報",
      description: "カウンセリング・セッション用の情報",
      links: {
        clinicHandoverList: "各クリニックの申し送り一覧",
        consentProcedures: "各種同意取得手順",
        counselingSlides: "カウンセリングスライド",
        counselingReportTemplate: "カウンセリング報告書のtemplate",
        googleCalendarEvent: "Google カレンダーの予定",
        googleMeet: "Google ミート",
        recordsIndex: "報告書一覧",
        reportGenerator: "報告書作成ファイル",
        pgtReport: "OVUS解析報告書",
        patientReportPdf: "カウンセリング報告書"
      }
    },
    clinicNotes: {
      title: "医療機関からの特記事項",
      empty: "なし"
    },
    counselingNotes: {
      title: "カウンセラー用メモ",
      placeholder: "メモを記載ください",
      saving: "保存中..."
    },
    patientInfo: {
      title: "連絡先",
      description: "患者の連絡先",
      country: "居住地国",
      prefecture: "都道府県"
    },
    search: {
      title: "検索",
      description: "すべてのフィールドは任意です。",
      appointmentId: "予約番号",
      patientId: "診察券番号",
      firstName: "名",
      lastName: "姓",
      email: "メールアドレス",
      date: "カウンセリング日時"
    },
    serviceInfo: {
      title: "患者の識別子",
      clinicName: "医療機関名",
      patientId: "診察券番号"
    },
    sessionFlow: {
      title: "セッション開始の流れ"
    }
  },
  dropdowns: {
    quickFilter: {
      today: "今日の予約",
      tomorrow: "明日の予約",
      yesterday: "昨日の予約"
    }
  },
  error: {
    acceptTos: "予約をする前に利用規約に同意する必要があります",
    incomplete: "未入力の項目があります。",
    invalidCode: "無効なコードです",
    invalidEmail: "無効なメールアドレス",
    invalidPatientId: "無効な診察券番号",
    invalidPhone: "数字(0-9)またはダッシュ(-)のみを使用する。"
  },
  function: {
    fullName: "{lastName} {firstName}"
  },
  help: {
    appointmentId:
      "ⓘ 予約番号をお聞かせいただけますと、お問い合わせ内容をカウンセリング担当者に転送いたしますので、より迅速に対応することができます。",
    date: {
      mobile: "ⓘ カレンダーの左上に表示されている「年」をタップして年を選択することができます。",
      desktop:
        "ⓘ カレンダーマークが表示されている場合は、カレンダーマークを選択の上で、左上の「年月」をクリックして年月を選択することができます。カレンダーマークが表示されない場合は、生年月日を直接ご入力ください。"
    },
    halfWidthCharacters:
      "ⓘ 半角英数字でご入力ください。アルファベットの大文字、小文字は区別されます。",
    pleaseSpecify: "ⓘ 具体的にお聞かせください。"
  },
  images: {
    logo: "PDnaviのロゴ"
  },
  preamble: {
    clinicCode: "通院されている医療機関のコードを\nご入力ください。",
    contactInfo:
      "本アンケートには、可能な限りカウンセリングをお受けになるご本人がご回答ください。カウンセリングの内容に影響を与える可能性がありますので、質問への回答はなるべく正確な情報をご記載ください。",
    contactInfo2: `カウンセリングを受ける方の連絡先をご入力ください。`,
    expectations: "今回のカウンセリングに対するご要望や現在のお気持ちについてお尋ねします。",
    family:
      "カップルのご家系内に生まれつきの病気や遺伝性の病気などの方がいらっしゃる場合は、ご予約者様との血縁関係と病名をお聞かせください。カウンセリング時に詳細をお伺いします。",
    pregnancyCurrent: "",
    pregnancyHistory: "これまでの妊娠・出産についてご入力ください。",
    prenatalTesting: "",
    profile: "カップルの生年月日をご入力ください。",
    review: {
      important: "まだ予約は完了していません。",
      lead: "以下の情報が正しいことをご確認ください。",
      main: "修正が必要な場合は「戻る」を押して修正してください。情報が正しい場合は利用規約にご同意のうえ、「送信」ボタンを押して予約リクエストをしてください。",
      note: "当日中に予約を取得しなかった場合、予約枠を含む一部の情報が失われる可能性がありますのでご注意ください。",
      notificationSent: "登録メールアドレスに予約情報が送信されました。"
    },
    selectDateTime: "予約の日時を選択してください。",
    selectService: "カウンセリング予約するサービスを以下よりお選びください。"
  },
  instruction: {
    changeAppointment: "予約をキャンセルまたは変更するには、{contactUs}からご連絡ください。",
    contactUs:
      "ご予約完了前後のご質問、ご予約の変更・キャンセルなどは、こちらのフォームよりお問い合わせください。",
    noClinic:
      "クリニックに所属していない場合、またはクリニック コードをお持ちでない場合は、{skip}。"
  },
  label: {
    appointment: "予約",
    appointmentDate: "予約日",
    appointmentDateTime: "カウンセリング日時",
    appointmentId: "予約番号",
    clinic: "クリニック",
    country: "お住まいの国",
    dob: "ご自身の生年月日",
    dobFemale: "女性の生年月日",
    dobPartner: "パートナーの生年月日",
    email: "メールアドレス",
    language: "言語",
    location: "所在地",
    message: "メッセージ",
    name: {
      first: "名",
      last: "姓",
      full: "お名前",
      alias: "カタカナ"
    },
    noRecords: "記録なし",
    patientId: "診察券番号",
    phone: "電話番号",
    registrant: "このアンケートをご入力いただいている方をご教示ください。",
    showing: "表示中",
    signedInAs: "ログイン中のユーザー",
    subdivision: "お住まいの都道府県",
    time: "時間",
    timezone: "タイムゾーン",
    tos: "規約の確認"
  },
  link: {
    contact: "お問い合わせ",
    contactUs: "こちらのフォーム",
    ovus: "OVUS",
    privacyPolicy: "プライバシーポリシー",
    skip: "このステップをスキップしてください",
    tos: "利用規約"
  },
  modal: {
    cancelAppointment: {
      title: "予約のキャンセル",
      description: "予約をキャンセルしますか？",
      details: {},
      input: {
        label: "確認する",
        description: "確認するには{code}を入力してください。"
      },
      button: "結果は理解していますので、予約をキャンセルしてください。"
    },
    clinicMessage: {
      title: "特記事項",
      description:
        "本患者様につきまして、カウンセリング担当者への連絡事項がございましたらご入力ください。（例：人工流産歴はパートナーには秘密、胎児異常歴など）",
      placeholder: "カウンセラーへのメッセージを追加してください。"
    },
    contactUs: {
      title: "お問い合わせ"
    },
    selectGeneticTests: {
      title: "実施検査",
      description: ""
    }
  },
  notification: {
    checkEmail: "メールをご確認の上で、ご予約のお手続きをお願いします。",
    confirmationAuthLink: "認証メールが送信されました：",
    confirmationAppointment: "確認メールが {email} に送信されました。",
    confirmationContact:
      "お問い合わせを受け付けました。 担当者よりご連絡いたしますので今しばらくお待ちください。",
    expiredAuthLink: "リンクの期限が切れています。{startNewSession}からログインしてください。",
    loadingCalendar: {
      first: "予約可能枠を確認中です。",
      second: "そのままお待ちください。"
    },
    noAvailability: "選択可能な時がありません。",
    noHistory: "なし",
    noInfo: "特記事項なし",
    noItems: "アイテムなし",
    notPregnant: "妊娠していない",
    reEnterEmail: "メールアドレスを再入力してください。",
    returnHome: "{sec} 秒後に自動的にホーム画面に戻ります。",
    startNewSession: "こちら",
    somethingWentWrong: "システムエラー　ホームに戻る。",
    unableToFindPage: "申し訳ありませんが、お探しのページが見つかりませんでした。"
  },
  page: {
    title: "PDnavi予約システム"
  },
  placeholder: {
    country: "あなたの国を選択",
    familyRelationship: "家族メンバーを選択してください",
    findByAppointmentId: "予約番号で検索",
    findByPatientId: "診察券番号で検索",
    language: "希望の言語を選択してください",
    message: "ここにメッセージを入力してください",
    select: "選択してください",
    subdivision: "都道府県を選択してください"
  },
  question: {
    attendancePregnantWoman:
      "妊婦さんがカウンセリングに参加される場合は、☐ をクリックしてください。"
  },
  sidebar: {
    appointmentList: "予約一覧",
    clinicList: "医療機関一覧",
    help: "ユーザーマニュアル",
    home: "ホーム"
  },
  table: {
    appointmentId: "予約番号",
    clinic: "医療機関名",
    clinicId: "コード",
    confirmedDateTime: "予約完了日時",
    counselorName: "カウンセラー",
    counselingDateTime: "カウンセリング日時",
    counselingNipt: "NIPTカウンセリング",
    dob: "生年月日",
    edd: "分娩予定日",
    empty: "なし",
    loading: "読み込み中...",
    isPrinted: "報告書印刷済み",
    patientReport: "カウンセリング報告書",
    privateMessage: "特記事項",
    name: "氏名",
    selectedTests: "実施検査",
    service: "サービス",
    status: "ステータス",
    weeksPregnant: "妊娠週数"
  },
  tabs: {
    appointmentPresent: "予約時アンケート",
    appointmentPast: "過去の予約一覧"
  },
  title: {
    appointment: "予約情報",
    clinicCode: "医療機関情報",
    clinicMessage: "特記事項",
    confirmationAppointment: "予約が確定しました",
    confirmationAuthLink: "メールをご確認ください",
    confirmationContact: "メッセージが配信されました",
    contact: "Contact Us",
    contactInfo: "連絡先",
    error: "システムエラー",
    expectations: "ご要望など",
    family: "ご家族について",
    fertilityTreatment: "不妊治療歴",
    fourZeroFour: "ページが見つかりません",
    pregnancyCurrent: "現在の妊娠",
    pregnancyHistory: "妊娠歴",
    prenatalTesting: "出生前検査",
    profile: "プロフィール",
    registrant: "登録者",
    reservation: "カウンセリング予約",
    review: "入力内容の確認",
    service: "サービス"
  },
  service: {
    prenatal: "出生前検査ホットライン",
    pgt: "着床前検査ホットライン",
    cancer: "がんと遺伝ホットライン"
  },
  status: {
    unconfirmed: "未確認",
    confirmed: "予約確定",
    complete: "カウンセリング終了",
    cancelled: "キャンセル",
    noShow: "無断キャンセル"
  },
  tos: {
    iAgree: "同意します。",
    iFullyAgree: "{tos}を読み、同意します。",
    label: "利用規約",
    ovus: "弊社は、お客様に対してカウンセリングサービスを提供するために、医療機関およびその委託を受けた{ovus}社より、お客様の着床前検査の結果（以下「検査結果」といいます）の開示を受けることになります。弊社は、法令・ガイドラインおよび弊社{privacyPolicy}を遵守して、検査結果を適正に取り扱います。",
    pdnavi:
      "本サービスは、{tos}を遵守するお客様にのみご利用いただくことができます。ご入力いただいた個人情報は、{privacyPolicy}にしたがって適正に取り扱います。"
  },
  validationMessage: {
    halfWidthAlphaNumericOnly: "半角英数字のみ。",
    invalidFormat: "指定された形式で入力してください。"
  }
};

export default ja;
