import { ref } from "vue";
import { doc, getDoc } from "firebase/firestore";

import { firestore } from "@/firebase/config";

const service = (() => {
  let data = ref({});

  const init = async (docId) => {
    console.debug("Initializing service data...", docId);
    const serviceDoc = await getDoc(doc(firestore, "services", docId));
    if (serviceDoc.exists) {
      data.value = {
        id: serviceDoc.id,
        ...serviceDoc.data()
      };
    }
    return;
  };

  return {
    init,
    data
  };
})();

export default service;
