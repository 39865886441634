<template>
  <router-view v-slot="{ Component }">
    <Transition>
      <component :is="Component" />
    </Transition>
  </router-view>
</template>

<script setup>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useHead } from "unhead";

import { changeLocale } from "@formkit/vue";

import user from "@/composables/user";
import { watchEffect } from "vue";

const { t, locale: i18nLocale } = useI18n({ useScope: "global" });

const { locale } = user.refs();

useHead({ title: t("page.title") });

onMounted(() => {
  // eslint-disable-next-line no-undef
  console.debug(`commit-${__GIT_HASH__.substring(0, 7)}`);
});

watchEffect(() => {
  // Set locale
  if (locale.value) {
    i18nLocale.value = locale.value; // https://vue-i18n.intlify.dev/guide/advanced/composition.html#locale-changing
    changeLocale(locale.value); // https://formkit.com/essentials/internationalization
  }
});
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
