import { ref } from "vue";
import { doc, onSnapshot, updateDoc, serverTimestamp } from "firebase/firestore";

import { auth, firestore } from "@/firebase/config";

import appointment from "@/composables/appointment";

import { isLocaleSupported } from "../common/locale/languages";

const user = (() => {
  const navLocale = navigator.language.slice(0, 2);
  let _unsubscribe;

  const id = ref("");
  const locale = isLocaleSupported(navLocale) ? ref(navLocale) : ref("ja");
  const email = ref("");

  const isAuth = ref(false);

  auth.onAuthStateChanged(async (_user) => {
    isAuth.value = _user ? true : false;

    // https://firebase.google.com/docs/reference/js/firebase.User
    // https://firebase.google.com/docs/auth/web/manage-users#get_a_users_provider-specific_profile_information
    if (_user) {
      console.debug("Initializing user data...", _user.uid);
      _unsubscribe = onSnapshot(doc(firestore, "users", _user.uid), async (doc) => {
        if (doc.exists) {
          id.value = doc.id;

          const _data = doc.data();
          if (!_data) {
            return;
          }

          // Fill `appointment` object
          if (Object.prototype.hasOwnProperty.call(_data, "appointmentId")) {
            await appointment.init(_data.appointmentId);
          }

          // Fill user data attributes
          if (Object.prototype.hasOwnProperty.call(_data, "locale")) {
            // console.debug("userLocale", _data.locale);
            locale.value = isLocaleSupported(_data.locale) ? _data.locale : "ja";
          }

          if (Object.prototype.hasOwnProperty.call(_data, "email")) {
            email.value = _data.email;
          }
        }
      });
    } else {
      if (_unsubscribe) {
        _unsubscribe();
      }
    }
  });

  const refs = () => {
    return {
      id,
      locale,
      email,
      isAuth
    };
  };

  const update = async (data) => {
    console.debug("Updating user data...", id.value);
    if (id.value) {
      await updateDoc(doc(firestore, "users", id.value), {
        updated: serverTimestamp(),
        ...data
      });
    } else {
      console.error("Undefined user ID");
    }
  };

  const logout = async () => {
    if (_unsubscribe) {
      _unsubscribe();
    }
    appointment.deinit();
    await auth.signOut();
    window.location.reload();
  };

  return {
    refs,
    update,
    logout
  };
})();

export default user;
