import { ref } from "vue";
import { doc, getDoc } from "firebase/firestore";

import { firestore } from "@/firebase/config";

const clinic = (() => {
  let data = ref({});

  const init = async (docId) => {
    console.debug("Initializing clinic data...", docId);
    const clinicDoc = await getDoc(doc(firestore, "clinics", docId));
    if (clinicDoc.exists) {
      data.value = {
        id: clinicDoc.id,
        ...clinicDoc.data()
      };
    }
  };

  return {
    init,
    data
  };
})();

export default clinic;
