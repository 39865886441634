const ovus = {
  en: {
    label: {
      caseRegistrationId: "Case registration number",
      eggId: "'Specimen control number' or 8-digit 'Identification number'",
      testReportDate: "Date of Analysis Report",
      cases: "Cases",
    },
    help: {
      caseRegistrationId:
        "ⓘ Located on the upper-left corner of your test report.",
      eggId: "ⓘ Found in the list of analysis results.",
      testReportDate:
        "ⓘ Indicated in the lower left corner of the list of analysis results.",
    },
    instruction: {
      report: "",
      case: "Please provide the following information for each test result that you would like to discuss during the counseling session.",
    },
    title: {
      report: "Analysis Report",
    },
  },
  ja: {
    label: {
      caseRegistrationId: "症例登録番号",
      eggId: "【検体管理番号】または8桁の【識別番号】",
      testReportDate: "解析報告書作成日",
      cases: "ケース",
    },
    help: {
      caseRegistrationId: "ⓘ 報告書の左上に記載。",
      eggId: "ⓘ 解析結果一覧に記載。",
      testReportDate: "ⓘ 解析結果一覧の左下に記載。",
    },
    instruction: {
      report:
        "今回のカウンセリングで相談したい検査結果が記載されている報告書の情報についてお尋ねします。",
      case: "ご相談したい検査結果について、報告書の情報を追加してください。",
    },
    title: {
      report: "解析報告書",
    },
  },
};

export default ovus;
