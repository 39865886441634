/**
 * Phone number rule
 *
 * Reference:
 * https://en.wikipedia.org/wiki/Telephone_numbers_in_Japan
 * @param {*} node
 * @returns True if value passes rule, False otherwise
 */
const phone = function (node) {
  const regex = /^[0-9-+]{9,17}$/g;
  const found = node.value.match(regex);
  return found ? true : false;
  // const p = node.value.trim().replace(/-|\.|\s/g, "");
  // return p.length >= 10 && p.length <= 11;
};

export default phone;
