const datetimeFormats = {
  en: {
    dob: { year: "numeric", month: "long", day: "numeric" },
    dateOnly: { dateStyle: "long" },
    timeOnly: {
      hour: "numeric",
      minute: "2-digit",
    },
    dateTime: {
      dateStyle: "long",
      timeStyle: "short",
    },
  },
  ja: {
    dob: { year: "numeric", month: "short", day: "numeric" },
    dateOnly: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    timeOnly: {
      hour: "2-digit",
      minute: "2-digit",
    },
    dateTime: {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    },
  },
};

export default datetimeFormats;
