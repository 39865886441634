import { functions } from "./config";
import { httpsCallable } from "firebase/functions";

export const sendSignInEmail = httpsCallable(functions, "auth-sendSignInEmail");
export const deleteUser = httpsCallable(functions, "auth-deleteUser");
export const confirmAppointment = httpsCallable(functions, "appointment-confirm");
export const cancelAppointment = httpsCallable(functions, "appointment-cancel");
export const createCheckoutSession = httpsCallable(functions, "stripe-createCheckoutSession");
export const getClinic = httpsCallable(functions, "clinic-get");
export const contactCounselor = httpsCallable(functions, "contact-counselor");
export const sendError = httpsCallable(functions, "contact-admin");
export const getVacancies = httpsCallable(functions, "vacancies-get");
