const expectations = {
  en: {
    discussions:
      "Is there any specific information or concerns you wish to discuss in detail with the counseling staff? You may describe them here.",
    expectations:
      "To ensure we address your needs effectively, please share your expectations for this counseling session.",
    sentiments: {
      // https://drive.google.com/file/d/1H6X7p_jbO-unLo8cBbtwRLGPppuQATQ5/view
      isDepressed:
        "During the past month, have you often been bothered by feeling down, depressed, or hopeless?",
      isDispleasured:
        "During the past month, have you often been bothered by little interest or pleasure in doing things?",
    },
  },
  ja: {
    discussions:
      "今回のカウンセリングで具体的にご相談したい内容や、カウンセリング担当者に伝えておきたいことがございましたら、お聞かせください。",
    expectations:
      "今回のカウンセリングに期待することとして、当てはまるものをすべてお選びください。（複数回答可）",
    sentiments: {
      isDepressed:
        "この一か月間、気分が沈んだり憂鬱な気持ちになったりすることがよくありましたか。",
      isDispleasured:
        "この一か月間、どうも物事に対して興味がわかない、あるいは心から楽しめない感じがよくありましたか。",
    },
  },
};

export default expectations;
