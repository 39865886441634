const family = {
  en: {
    parent: "Parent",
    relationship: "Relationship",
    geneticDisorders: {
      list: "Condition(s)",
      other:
        "ⓘ Please write the names of other conditions here. Separate each condition with a comma (,).",
    },
  },
  ja: {
    parent: "女性側の血縁者/パートナー側の血縁者",
    relationship: "血縁関係",
    geneticDisorders: {
      list: "病名",
      other:
        "ⓘ 具体的にお聞かせください。複数ある場合は、指摘されている内容・病気の名称をカンマで区切って下さい。",
    },
  },
};

export default family;
